@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap');

h1, h2, h3, h4, h5, h6, p, button, label, input, a, span, div, li, ul, ol, textarea, select {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.arabic-text {
  font-family: 'Noto Kufi Arabic', -apple-system, sans-serif !important;
  direction: rtl;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #003E1F;
  --secondary-color: #F9A602;
  --black-color: #000001;
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 768px) {
    .mobile-only {
        display: block;
    }
}

$breakpoints: (
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xl': 1200px,
        'xxl': 1400px
);

$max-container-width: (
        'sm': 540px,
        'md': 720px,
        'lg': 960px,
        'xl': 1140px,
        'xxl': 1320px
);

.custom-container {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $min-width in $breakpoints {
    @media (min-width: $min-width) {
      max-width: map-get($max-container-width, $breakpoint);
    }
  }
}

