.navbarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    margin-top: 20px;
    height: 80px;
    border-radius: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

    .logo {
      width: 70px;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0 10px;
            padding: 0;
            a {
              color: white;
              text-decoration: none;
              font-size: 16px;
              font-weight: 600;

              &:hover {
                color: var(--secondary-color);
              }

              &.active {
                color: var(--secondary-color);
              }
            }
        }
    }
  }

  @media screen and (max-width: 768px) {
    nav {
      margin-top: 0 !important;
      border-radius: 0 0 10px 10px !important;

      ul {
        display: none;
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 100vh;
  padding: 20px;
  background-color: rgba(0, 62, 31, 0.84);
  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .logo {
    width: 70px;
    margin: 0 auto;
  }

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  h2 {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 10px;
      padding-bottom: 20px;
      a {
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
}