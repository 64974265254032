.mainSection {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 99vh;
  width: 100%;

  .topFilter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    opacity: 0.5;
    z-index: 0;
  }

  .textSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: 1;

    h1 {
      color: white;
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h2 {
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media screen and (max-width: 768px) {
      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 1.3rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  .flag1 {
    width: 240px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    animation: smoothMove 3s infinite;
  }

  .flag2 {
    width: 240px;
    position: absolute;
    top: 20px;
    right: 20px;
    animation: smoothMove 3s infinite;
  }

  .actionButton {
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.3rem;
  }
}

.primarySection {
  h2 {
    color: var(--primary-color);
  }

  p {
    color: var(--black-color);
  }
}

.greenBackground {
  // Make Background Gradient
    background-image: linear-gradient(
        to right bottom,
        green,
        rgba(0, 255, 0, 0.5)
    );
}

.gallery {
  img {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}

// Smooth Moving Animation
@keyframes smoothMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 400px;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: white;

  img {
    border-radius: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
  }

  p {
    font-size: 1rem;
    color: var(--black-color);
  }
}